/* You can add global styles to this file, and also import other style files */
.plotlyjsicon{
    display: none !important;
}

.page-header-title
{
    font-size: 20px;
    font-weight: 600;
    color:#049fc3;
    margin: 0;
}

.cursor-pointer
{
    cursor: pointer;
}